import React, { useState } from 'react';
import Modal from 'react-modal';
import { consentContext } from '../../context/ConsentContext';
import logoCookieBar from '../../logoCookieBar.svg';
import './cookieConsentModal.css';

Modal.setAppElement(`#___gatsby`);

function CookieConsentModal(): JSX.Element {
  const [showCookieDetails, toggleCookieDetails] = useState(false);

  return (
    <consentContext.Consumer>
      {(context) => (
        <React.Fragment>
          <Modal
            contentLabel="Modal"
            isOpen={context.modalVisibility}
            onRequestClose={context.setModalVisibility}
            className="content__styling"
            style={{
              overlay: {
                position: 'absolute',
                outline: 'none',
                top: '50%',
                bottom: '50%',
              },
              content: {
                top: '32%',
                left: '37%',
                right: '37%',
                bottom: '30%',
                outline: 'none',
                border: '2px solid var(--primary-color)',
                boxshadow: '3px 3px 5px rgba(0, 0, 0, 0.3)',
                padding: '15px 20px 6px 20px',
                background: 'rgb(236, 236, 242)',
                maxWidth: '26%',
                flexDirection: 'column',
              },
            }}
          >
            <div style={{ display: 'flex' }}>
              <img
                style={{ width: '60px' }}
                src={logoCookieBar}
                alt={'Stiftung'}
              />
              <h3 className="consentContext__headline">
                Datenschutzeinstellungen
              </h3>
            </div>
            <p className="consentContext__cookie-text">
              Wir nutzen Cookies (einschließlich von Drittanbietern) auf unserer
              Website. Einige von ihnen sind essentiell, während andere uns
              helfen diese Website optimal zu gestalten und fortlaufend zu
              verbessern.
              <br /> Es werden keine optionalen Cookies für den Zugang zu
              externen Medien gesetzt, außer diese werden von Ihnen nachfolgend
              aktiviert. Die vorgenommene Cookie-Einstellung kann jederzeit
              geändert werden.
            </p>
            <div className="consentContext__container">
              <div className="consentContext__cookie-button">
                <div className="consentContext__first-cookie-button">
                  <button className="consentContext__button-styling">
                    {'✔'}
                  </button>{' '}
                  Essentiell
                </div>

                <div className="consentContext__second-cookie-button">
                  <button
                    className="consentContext__button-styling"
                    onClick={(): void => context.toggleConsent()}
                  >
                    {context.cookieConsent === true ? '✔' : 'o'}
                  </button>{' '}
                  Externe Medien
                </div>
              </div>

              <div className="consentContext__cookie-button-middle">
                <button
                  className="consentContext__first-cookie-button-middle"
                  onClick={(): void => {
                    context.giveFullConsent();
                    // TODO: set some timeout
                    setTimeout(500);
                    context.toggleModalVisibility();
                  }}
                >
                  Alle akzeptieren
                </button>
                <button
                  className="consentContext__second-cookie-button-middle"
                  onClick={(): void => {
                    // TODO: set some timeout
                    setTimeout(500);
                    context.toggleModalVisibility();
                  }}
                >
                  Speichern
                </button>
              </div>
              {showCookieDetails && (
                <div className="consentContext__cookie-details-text">
                  <div className="consentContext__cookie-essenziell-text">
                    <div style={{ display: 'flex', marginBottom: '3px' }}>
                      <h3 className="consentContext__second-headline">
                        Essenziell{'  '}
                      </h3>
                      <button className="consentContext__button-styling">
                        {'✔'}
                      </button>
                    </div>

                    <div className="consentContext__cookie-detail-text">
                      <p>
                        <b>Name:</b> consent
                      </p>
                      <p>
                        <b>Anbieter:</b> Eigentümer der Website
                      </p>
                      <p>
                        <b>Zweck:</b> Speichert persönliche Cookie-Einstellungen
                        auf dieser Seite.
                      </p>
                      <p>
                        <b>Datenschutzerklärung: </b>
                        <a href={'/datenschutz'}>
                          https://internationalestiftung.com/datenschutz
                        </a>
                      </p>
                      <p>
                        <b>Cookie Name:</b> consent
                      </p>
                      <p>
                        <b>Cookie-Laufzeit:</b> Laufzeit 12 Monate
                      </p>
                    </div>
                  </div>

                  <div className="consentContext__cookie-essenziell-text">
                    <div style={{ display: 'flex', marginBottom: '3px' }}>
                      <h3 className="consentContext__second-headline">
                        YouTube {'  '}
                      </h3>{' '}
                      <button
                        className="consentContext__button-styling"
                        onClick={(): void => context.toggleConsent()}
                      >
                        {context.cookieConsent === true ? '✔' : 'o'}
                      </button>
                    </div>
                    <div className="consentContext__cookie-detail-text">
                      <p>
                        <b>Name:</b> YouTube
                      </p>
                      <p>
                        <b>Anbieter:</b> YouTube
                      </p>
                      <p>
                        <b>Zweck:</b> Wird verwendet, um YouTube-Inhalte zu
                        entsperren.
                      </p>
                      <p>
                        <b>Datenschutzerklärung: </b>
                        <a href={'https://policies.google.com/privacy'}>
                          https://policies.google.com/privacy
                        </a>
                      </p>
                      <p>
                        <b>Host:</b> <a href={'google.com'}>google.com</a>
                      </p>
                      <p>
                        <b>Cookie Name:</b> NID
                      </p>
                      <p>
                        <b>Cookie-Laufzeit:</b> Laufzeit 6 Monate
                      </p>
                    </div>
                    <div>
                      <div className="consentContext__cookie-detail-text">
                        <p>
                          <b>Name:</b> VISITOR_INFO1_LIVE
                        </p>
                        <p>
                          <b>Anbieter:</b> YouTube
                        </p>
                        <p>
                          <b>Zweck:</b> Wird verwendet, um die Besucheranzahl
                          der Videos zu identifizieren.
                        </p>
                        <p>
                          <b>Datenschutzerklärung: </b>
                          <a href={'https://policies.google.com/privacy'}>
                            https://policies.google.com/privacy
                          </a>
                        </p>
                        <p>
                          <b>Host:</b> <a href={'youtube.com'}>youtube.com</a>
                        </p>
                        <p>
                          <b>Cookie-Laufzeit:</b> Laufzeit 180 Tage
                        </p>
                      </div>
                    </div>
                    <div>
                      <div className="consentContext__cookie-detail-text">
                        <p>
                          <b>Name:</b> YSC
                        </p>
                        <p>
                          <b>Anbieter:</b> YouTube
                        </p>
                        <p>
                          <b>Beschreibung:</b> YouTube ist eine von Google
                          betriebene Plattform zum Hosten und Teilen von Videos.
                          YouTube sammelt die IDs der Nutzer, um Statistiken der
                          Videos von YouTube, die der Benutzer gesehen hat, zu
                          führen.
                        </p>
                        <p>
                          <b>Datenschutzerklärung: </b>
                          <a href={'https://policies.google.com/privacy'}>
                            https://policies.google.com/privacy
                          </a>
                        </p>
                        <p>
                          <b>Host:</b> <a href={'youtube.com'}>youtube.com</a>
                        </p>
                        <p>
                          <b>Cookie-Laufzeit:</b> Besuch auf der Seite
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              <div
                className="consentContext__cookie-button-end"
                style={{ display: 'flex' }}
              >
                {!showCookieDetails && (
                  <div>
                    <button
                      className="consentContext__first-cookie-button-end"
                      onClick={toggleCookieDetails}
                    >
                      Cookie-Details
                    </button>
                    {' | '}
                  </div>
                )}
                <div className="consentContext__second-cookie-button-end">
                  <a href={'/datenschutz'}>Datenschutzerklärung</a>|{' '}
                  <a href={'/impressum'}>Impressum</a>
                </div>
              </div>
            </div>
          </Modal>
        </React.Fragment>
      )}
    </consentContext.Consumer>
  );
}

export default CookieConsentModal;
