import React from 'react';
import './menuItem.css';
import { Link } from 'gatsby';

interface Props {
  title: string;
  href: string;
  children?: JSX.Element;
}

export default function MenuItem(props: Props): JSX.Element {
  const { title, href, children } = props;

  return (
    <div className="menu-item">
      <Link className={`menu-item__link`} activeClassName="active" to={href}>
        {title}
      </Link>
      {children}
    </div>
  );
}
