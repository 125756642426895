import React, { useState } from 'react';
import './burgermenu.css';
import { slide as Menu } from 'react-burger-menu';
import Menubar from '../menu/menubar';

export interface BurgerMenuProps {
  children?: JSX.Element[] | JSX.Element;
}

export default function BurgerMenu(props: BurgerMenuProps): JSX.Element {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="burger-menu">
      <Menu right onStateChange={(value): void => setIsOpen(value.isOpen)}>
        <Menubar key={`${isOpen}`} styleClass="mobile-menu" />
        {props.children}
      </Menu>
    </div>
  );
}
