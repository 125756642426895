import React from 'react';
import './sidebar.css';

export default function Sidebar({
  children,
}: {
  children: JSX.Element[] | JSX.Element;
}): JSX.Element {
  return <div className="sidebar">{children}</div>;
}
