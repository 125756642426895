import React from 'react';
import './main-menu.css';
import Dropdown from './dropdown/dropdown';
import MenuItem from './menuItem/menuItem';
import '../../media-queries/menu-media-queries.css';

export interface MenubarProps {
  styleClass?: string;
}

export default function Menubar({ styleClass }: MenubarProps): JSX.Element {
  return (
    <nav className={`menubar ${styleClass ?? ''}`}>
      <div className="menubar__container">
        <MenuItem title="" href="/" />
        <MenuItem title="Die Stiftung" href="/die-stiftung" />
        <MenuItem title="Der Stifter" href="/der-stifter" />
        <Dropdown
          title="Projekte"
          href="/projekte"
          items={[
            {
              title: 'Gesamtübersicht',
              href: '/projekte',
            },
            {
              title: 'Musik am Nachmittag',
              href: '/projekte/musik-am-nachmittag',
            },
            {
              title: 'Toujours Mozart',
              href: '/projekte/toujours-mozart',
            },
            {
              title: 'Musik für Schüler',
              href: '/projekte/musik-fuer-schueler',
            },
            {
              title: 'Amnesty National',
              href: '/projekte/amnesty-national',
            },
            {
              title: 'Musik auf Rädern',
              href: '/projekte/musik-auf-raedern',
            },
          ]}
        />

        <MenuItem title="Veranstaltungen" href="/veranstaltungen" />
        <MenuItem title="Fischer's Corner" href="/fischers-corner" />
        <MenuItem title="aktuell-speziell" href="/aktuell-speziell" />
        <MenuItem title="Dank" href="/dank" />

        <Dropdown
          title="Kontakt"
          href="/kontakt"
          items={[
            { title: 'Who is who', href: '/kontakt#who-is-who' },
            { title: 'Spendenkonto', href: '/kontakt#spendenkonto' },
            { title: 'Impressum', href: '/impressum' },
            { title: 'Datenschutz', href: '/datenschutz' },
          ]}
        />
      </div>
    </nav>
  );
}
