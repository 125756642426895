import React, { useState } from 'react';
import './dropdown.css';
import '../../../media-queries/menu-media-queries.css';
import MenuItem from '../menuItem/menuItem';

/**
 * Funktion für das Dropdownmenü
 */

interface DropdownItem {
  title: string;
  href: string;
}

interface DropdownItemsProps {
  items: DropdownItem[];
}

function DropdownItem(props: DropdownItemsProps): JSX.Element {
  return (
    <>
      {props.items.map((item, index) => (
        <li className="dropdown-menu__item" key={index}>
          <a className="dropdown-menu__link" href={item.href}>
            {item.title}
          </a>
        </li>
      ))}
    </>
  );
}

interface Props {
  title: string;
  href: string;
  items: DropdownItem[];
}

export default function Dropdown(props: Props): JSX.Element {
  const { title, href, items } = props;
  const [inHover, setHover] = useState(false);

  return (
    <div
      className="dropdown__container"
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <span></span>
      <MenuItem title={title} href={href}>
        {inHover && (
          <div className="dropdown">
            <div className="dropdown-menu__container">
              <ul className="dropdown__menu">
                <DropdownItem items={items} />
              </ul>
            </div>
          </div>
        )}
      </MenuItem>
    </div>
  );
}
