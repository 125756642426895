import React from 'react';
import './main-header.css';

export interface HeaderProps {
  children: JSX.Element[] | JSX.Element;
}

export default function Header(props: HeaderProps): JSX.Element {
  return <header className="main-header">{props.children}</header>;
}
