import React, { useState } from 'react';

import './basic-structure.css';
import './../../styles/style.css';

import Sidebar from '../sidebar/sidebar';
import Header from '../header/header';
import CookieConsentModal from '../basic-structure/cookieConsentModal';

interface Props {
  children: JSX.Element[] | JSX.Element;
}

function BasicStructure(props: Props): JSX.Element {
  const childArray = React.Children.toArray(props.children);
  const sidebar = childArray.filter(
    (c) => c.type && c.type === React.createElement(Sidebar).type,
  );
  const header = childArray.filter(
    (c) => c.type && c.type === React.createElement(Header).type,
  );
  const otherChildren = childArray.filter(
    (c) =>
      c.type &&
      c.type !== React.createElement(Sidebar).type &&
      c.type !== React.createElement(Header).type,
  );

  return (
    <div>
      <CookieConsentModal></CookieConsentModal>
      <div className="main-wrapper">
        {sidebar}
        <div className="content-wrapper">
          {header}
          {otherChildren}
        </div>
      </div>
    </div>
  );
}
export default BasicStructure;
